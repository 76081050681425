import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	getFirestore,
	updateDoc,
} from 'firebase/firestore';
import '../../../firebase';

import { message } from 'utils/GlobalComponent';
const db = getFirestore();

export const fetchOrderStatuses = createAsyncThunk(
	'orderStatuses/fetchOrderStatuses',
	async (_, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const statusesCollection = collection(
				db,
				`/business_collection/${idBusiness}/order-statuses`
			);
			const snapshot = await getDocs(statusesCollection);

			const statuses = [];
			snapshot.forEach((doc) => {
				const data = doc.data();

				if (!data.parentStatusId) {
					statuses.push({
						id: doc.id,
						title: data.title || 'Без названия',
						color: data.color || '#fff9f9',
					});
				}
			});

			return statuses;
		} catch (error) {
			console.error('Помилка під час завантаження статусів замовлень:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const addOrderStatus = createAsyncThunk(
	'orderStatuses/addOrderStatus',
	async ({ statusData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			if (!idBusiness) throw new Error('Business ID is missing');

			const statusesCollection = collection(
				db,
				`/business_collection/${idBusiness}/order-statuses`
			);
			await addDoc(statusesCollection, statusData);
			message.success('Order status successfully added!');
			return statusData;
		} catch (error) {
			message.error('Error adding order status');
			return rejectWithValue(error.message);
		}
	}
);

export const editOrderStatus = createAsyncThunk(
	'orderStatuses/editOrderStatus',
	async ({ statusId, statusData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			if (!idBusiness) throw new Error('Business ID is missing');

			const statusRef = doc(db, `/business_collection/${idBusiness}/order-statuses/${statusId}`);
			await updateDoc(statusRef, statusData);
			message.success('Order status successfully updated!');
			return { id: statusId, ...statusData };
		} catch (error) {
			message.error('Error updating order status');
			return rejectWithValue(error.message);
		}
	}
);

export const deleteOrderStatus = createAsyncThunk(
	'orderStatuses/deleteOrderStatus',
	async ({ statusId }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			if (!idBusiness) throw new Error('Business ID is missing');

			await deleteDoc(doc(db, `/business_collection/${idBusiness}/order-statuses/${statusId}`));
			message.success('Order status successfully deleted!');
			return statusId;
		} catch (error) {
			message.error('Error deleting order status');
			return rejectWithValue(error.message);
		}
	}
);

export const fetchOrderSubStatuses = createAsyncThunk(
	'orderSubStatuses/fetchOrderSubStatuses',
	async (_, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;
			if (!idBusiness) throw new Error('Business ID is missing');

			const statusesCollection = collection(
				db,
				`/business_collection/${idBusiness}/order-statuses`
			);
			const snapshot = await getDocs(statusesCollection);

			const subStatuses = {};

			snapshot.forEach((doc) => {
				const data = doc.data();
				if (data.parentStatusId) {
					if (!subStatuses[data.parentStatusId]) subStatuses[data.parentStatusId] = [];
					subStatuses[data.parentStatusId].push({
						id: doc.id,
						title: data.title,
						color: data.color,
					});
				}
			});

			console.log('Final subStatuses:', subStatuses);
			return subStatuses;
		} catch (error) {
			console.error('Error fetching subStatuses:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const addOrderSubStatus = createAsyncThunk(
	'orderSubStatuses/addOrderSubStatus',
	async ({ parentStatusId, subStatusData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;
			if (!idBusiness) throw new Error('Business ID is missing');

			const statusesCollection = collection(
				db,
				`/business_collection/${idBusiness}/order-statuses`
			);
			const docRef = await addDoc(statusesCollection, {
				...subStatusData,
			});

			message.success('Sub-status successfully added!');
			return { id: docRef.id, parentId: parentStatusId, ...subStatusData };
		} catch (error) {
			message.error('Error adding sub-status');
			return rejectWithValue(error.message);
		}
	}
);

export const editOrderSubStatus = createAsyncThunk(
	'orderSubStatuses/editOrderSubStatus',
	async ({ subStatusId, subStatusData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;
			if (!idBusiness) throw new Error('Business ID is missing');

			const subStatusRef = doc(
				db,
				`/business_collection/${idBusiness}/order-statuses/${subStatusId}`
			);
			await updateDoc(subStatusRef, {
				title: subStatusData.title,
			});

			message.success('Sub-status successfully updated!');
			return { id: subStatusId, title: subStatusData.title };
		} catch (error) {
			message.error('Error updating sub-status');
			return rejectWithValue(error.message);
		}
	}
);

export const deleteOrderSubStatus = createAsyncThunk(
	'orderSubStatuses/deleteOrderSubStatus',
	async ({ subStatusId }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;
			if (!idBusiness) throw new Error('Business ID is missing');

			const subStatusRef = doc(
				db,
				`/business_collection/${idBusiness}/order-statuses/${subStatusId}`
			);
			await deleteDoc(subStatusRef);

			message.success('Sub-status successfully deleted!');
			return subStatusId;
		} catch (error) {
			message.error('Error deleting sub-status');
			return rejectWithValue(error.message);
		}
	}
);
