import { createSlice } from '@reduxjs/toolkit';
import {
	fetchOrderSubStatuses,
	addOrderSubStatus,
	editOrderSubStatus,
	deleteOrderSubStatus,
} from './thunks';

const orderSubStatusesSlice = createSlice({
	name: 'orderSubStatuses',
	initialState: { items: {}, loading: false, error: null },
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchOrderSubStatuses.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchOrderSubStatuses.fulfilled, (state, action) => {
				console.log('Fetched subStatuses:', action.payload);
				state.loading = false;
				state.items = action.payload;
				console.log('Redux store after update:', state.items);
			})
			.addCase(fetchOrderSubStatuses.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(addOrderSubStatus.fulfilled, (state, action) => {
				const { parentStatusId, ...subStatus } = action.payload;
				if (!state.items[parentStatusId]) state.items[parentStatusId] = [];
				state.items[parentStatusId].push(subStatus);
			})
			.addCase(editOrderSubStatus.fulfilled, (state, action) => {
				const { parentStatusId, id } = action.payload;
				if (state.items[parentStatusId]) {
					const index = state.items[parentStatusId].findIndex((item) => item.id === id);
					if (index !== -1) state.items[parentStatusId][index] = action.payload;
				}
			})
			.addCase(deleteOrderSubStatus.fulfilled, (state, action) => {
				const { parentStatusId, id } = action.payload;
				if (state.items[parentStatusId]) {
					state.items[parentStatusId] = state.items[parentStatusId].filter(
						(item) => item.id !== id
					);
				}
			});
	},
});

export const OrderSubStatusesReducer = orderSubStatusesSlice.reducer;
